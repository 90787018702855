@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body,
html {
  overflow: hidden;
}

body,
.ant-layout {
  background-color: #e5e5e5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333 !important;
}

#root {
  padding: 15px;
}

.site-page {
  height: calc(100vh - 30px);
  //overflow: hidden;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

.sider-stylewhere {
  color: #fff !important;
  font-weight: 800 !important;
  font-size: 20px !important;
  font-family: 'Open Sans', sans-serif !important;
  text-align: center !important;
}

.spin {
  display: inline-block;
  -webkit-animation: spinCircle 2s infinite linear;
  animation: spinCircle 2s infinite linear;
}

@-webkit-keyframes spinCircle {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinCircle {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-submenu-selected .ant-menu-submenu-title {
  display: flex;
  flex-direction: column;
  line-height: normal;
  margin-top: 8px;
  margin-bottom: 5px !important;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 0px !important;
}

.ant-menu-item .ant-menu-item-icon {
  color: #fff;
  font-size: 30px !important;
}

.ant-layout-sider .ant-menu .ant-menu-item .icona,
.ant-menu-submenu-title .ant-menu-item-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 90%;
  width: 100%;
  height: 50px;
}

.ant-menu-item .ant-menu-title-content,
.ant-menu-submenu-title .ant-menu-title-content {
  font-family: 'Roboto', sans-serif;
  margin-top: 0px;
  text-align: center;
  margin-left: 0px !important;
  color: #fff !important;
  opacity: 1 !important;
  font-size: 10px !important;
  line-height: 12px;
}

.ant-menu-title-content .ant-badge-count {
  box-shadow: unset;
  top: 5px;
  right: 5px;
  transform: unset;
  padding: 0px 5px;
  font-size: 10px;
  height: 16px;
  line-height: 16px;
  position: absolute;
  background-color: #ceff00;
  border-radius: 15px;
  color: #333;
}

.ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-submenu-selected .ant-menu-submenu-title {
  background-color: #4e4e4e !important;
}

.ant-layout-header {
  border-radius: 10px;
  margin-top: 10px;
}

.site-layout {
  margin-left: 15px;
  background: #e5e5e5;
}

.ant-layout-content {
  overflow-y: auto;
  overflow-x: hidden;
}

.ant-layout-content.hidden {
  overflow: hidden;
}

.stw-scroller {
  overflow: auto;
  overflow-x: hidden;
  height: 100%;
}

.stw-section-page {
  background-color: #fff !important;
  padding: 15px !important;
  margin-top: 0px;
  border-radius: 10px;
  margin-bottom: 15px;
  min-width: 100%;
}

.stw-section-page:last-child {
  margin-bottom: 0px !important;
}

.stw-section-page.nopadding {
  padding: 0px !important;
}

.stw-section-page.paged-header {
  height: calc(100% - 89px);
}

.stw-section-page.paged-header.stw-border {
  padding: 0px !important;
}

.stw-section-page.paged-header.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 89px);
}

.stw-section-page.paged-header.transparent {
  padding: 0px !important;
  background-color: transparent !important;
}

.stw-section-page.paged-header.filtered {
  height: calc(100% - 158px);
}

.stw-section-page.paged-header.boxed {
  height: calc(100% - 74px);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding-top: 0px !important;
}

.stw-section-page.paged-header.noheight {
  height: auto;
}

.stw-section-page.fill {
  min-height: 100%;
  margin: 0;
}

.stw-section-page.fullheight {
  height: 100%;
  margin: 0;
}

.stw-section-page.fullheight.scrollable {
  overflow-y: auto;
}

.stw-section-page.nomb {
  margin-bottom: 0px;
}

.stw-section-page h1 {
  color: #333;
  font-size: 18px;
  font-weight: bold;
}

.ant-layout-content.fullwidth {
  overflow-y: auto;
  overflow-x: hidden;
}

.ant-layout-content.fullwidth .stw-section-page {
  width: 100%;
}

.ant-layout-content.fullwidth .ant-table-wrapper {
  overflow-x: hidden;
}

.ant-form-vertical.fullheight,
.ant-layout.fullheight,
.ant-layout.fullheight .site-layout {
  height: 100%;
}

.ant-layout.fullheight .ant-layout-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* SIDER MENU */
.ant-layout-sider {
  padding: 20px 0px 10px 0px;
  border-radius: 10px;
  background-color: #333 !important;
  flex: 0 0 300px !important;
  max-width: 300px !important;
  min-width: 300px !important;
  width: 300px !important;
}

.ant-layout-sider-collapsed {
  width: 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
}

.ant-layout-sider:not(.ant-layout-sider-collapsed) .stw-sider-title {
  flex: 1;
  text-align: left !important;
  margin-left: 5px;
}

.ant-layout-sider:not(.ant-layout-sider-collapsed) .ant-layout-sider-children .ant-menu {
  min-width: 66px;
  max-width: 66px;
  margin: 0px 4px 0px 10px !important;
}

.stw-sider-collapse {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  background-color: #333;
  border-radius: 10px;
  display: block;
}

.ant-layout-sider-collapsed .stw-sider-collapse {
  transform: rotate(180deg);
  position: absolute;
  top: 3px;
  right: -7px;
}

.ant-layout-sider-collapsed .ant-row {
  flex-wrap: unset;
}

.ant-layout-sider-collapsed .ant-layout-sider-children .ant-menu {
  min-width: 80px;
  max-width: 80px;
}

.ant-layout-sider .ant-menu {
  background-color: #333 !important;
  height: calc(100% - 8px);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

.ant-menu-submenu {
  cursor: pointer !important;
  padding: 0px 10px;
}

.ant-layout-sider .ant-menu .ant-menu-item,
.ant-menu-submenu .ant-menu-submenu-title {
  flex: 1;
  max-height: 70px;
  min-height: 70px;
  padding-bottom: 5px !important;
}

.ant-layout-sider .ant-menu .ant-menu-item {
  border: 1px solid #333;
  margin-right: 0;
  margin-bottom: 0 !important;
  margin-left: 0;
  width: 100%;
}

.ant-layout-sider .ant-menu .ant-menu-item:hover {
  border: 1px solid #4e4e4e !important;
}

.ant-layout-sider-children,
.ant-menu-submenu-title {
  display: flex;
  flex-direction: column;
  padding: 0px !important;
}

.stw-sider-title {
  color: #fff !important;
  font-weight: 800 !important;
  font-size: 22px !important;
  font-family: 'Open Sans', sans-serif !important;
  text-align: center !important;
  flex: 1;
  line-height: 24px !important;
  margin-bottom: 10px !important;
}

.ant-layout-header {
  border-radius: 10px;
  margin-top: 10px;
}

.stw-sider-menu-row {
  display: flex;
  flex-flow: row;
}

.stw-sider-menu-row.overflow {
  height: calc(100% - 102px);
}

.stw-sider-submenu-row {
  flex: 1;
  margin-left: 15px;
  flex-direction: column;
}

.stw-sider-submenu-row.list {
  height: calc(100% - 19px);
  margin-top: 9px;
  margin-left: 5px;
  margin-right: 10px;
}

.stw-sider-submenu-row h5 {
  color: #fff !important;
}

.stw-sider-submenu-title {
  font-weight: 700 !important;
  margin-bottom: 6px !important;
  font-family: 'Roboto', sans-serif !important;
  flex: 1;
  line-height: 28px !important;
}

.ant-menu-submenu-popup {
  min-width: 250px;
}

.stw-stylewhere-submenu1:not(.ant-menu-submenu-hidden),
.stw-stylewhere-submenu3:not(.ant-menu-submenu-hidden),
.stw-stylewhere-submenu4:not(.ant-menu-submenu-hidden) {
  top: 15px !important;
  display: flex;
  height: calc(100vh - 30px);
}

.stw-stylewhere-submenu1:not(.ant-menu-submenu-hidden) {
  align-items: center;
}

.stw-stylewhere-submenu3:not(.ant-menu-submenu-hidden),
.stw-stylewhere-submenu4:not(.ant-menu-submenu-hidden) {
  align-items: center;
}

.ant-menu-submenu-popup .ant-menu-sub {
  background-color: #333 !important;
  border-radius: 10px !important;
  padding: 10px;
  padding-top: 0px;
  width: 100%;
  max-height: calc(100vh - 30px);
}

.ant-menu-submenu-popup .ant-menu-sub .ant-menu-item:last-child {
  margin-bottom: 0px !important;
}

.stw-sider-submenu-element,
.stw-sider-submenu-element a,
.ant-menu-submenu-popup .ant-menu-title-content {
  font-weight: 700 !important;
  font-size: 12px !important;
  font-family: 'Roboto', sans-serif !important;
  color: #fff !important;
  line-height: 20px !important;
  cursor: pointer;
  width: 100% !important;
  display: inline-block;
  text-align: left;
}

.stw-sider-submenu-element,
.ant-menu-submenu-popup .ant-menu-title-content {
  margin-bottom: 5px !important;
  margin-top: 0px;
  padding: 10px;
  border-radius: 5px;
}

.ant-menu-submenu-popup .ant-menu-title-content {
  padding: 10px 10px 5px 10px !important;
}

.stw-sider-submenu-element.active,
.stw-sider-submenu-element.active a,
.stw-sider-submenu-element:hover,
.stw-sider-submenu-element:hover a,
.ant-menu-submenu-popup .ant-menu-item:hover span {
  color: #fff !important;
}

.stw-sider-submenu-element:hover,
.stw-sider-submenu-element.active,
.ant-menu-submenu-popup .ant-menu-item:hover {
  background-color: #4e4e4e;
}

.stw-stylewhere-submenu4 .stylewhere-button-default,
.stw-sider-submenu-row.list .stylewhere-button-default {
  background-color: #4e4e4e !important;
  color: #fff !important;
  border: 0px !important;
  width: 100%;
  padding: 0px 10px;
  text-align: center;
  line-height: 36px !important;
  height: 36px !important;
  font-size: 14px;
}

.stw-stylewhere-submenu4 .stylewhere-button-default:hover,
.stw-sider-submenu-row.list .stylewhere-button-default:hover {
  background-color: #4e4e4e !important;
}

.stw-sider-submenu-element.notification {
  border: 1px solid #4e4e4e;
  margin-bottom: 10px !important;
  height: auto !important;
}

.stw-sider-submenu-element.notification .stw-sider-notification {
  gap: 0px !important;
}

.stw-sider-submenu-element.notification .stw-sider-notification-title {
  align-items: flex-start;
}

.stw-sider-submenu-element.notification .ant-menu-title-content {
  padding-bottom: 0px !important;
}

.stw-sider-submenu-element.notification.active,
.stw-sider-submenu-element.notification:hover {
  background-color: #666 !important;
}

.stw-sider-notification {
  width: 100%;
}

.stw-sider-notification-title {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  align-items: center;
}

.stw-sider-notification-title .status {
  flex: 0;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  background-color: #4e4e4e;
  margin-right: 5px;
  border-radius: 2px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-image: url(../../icon/read.png);
}

.stw-sider-notification-title .status.unread {
  background-color: #ceff00;
  background-image: url(../../icon/menu/icon-menu-notification-black.png);
}

.stw-sider-notification-title span {
  font-weight: 300;
}

.stw-sider-notification-title span.label {
  flex: 1;
  margin-left: 2px;
  margin-right: 0px;
  word-break: break-all;
  line-height: 18px;
  font-size: 12px;
}

.stw-sider-notification span.time {
  color: #a1a1a1;
  font-size: 11px;
  font-weight: 300;
  text-align: right;
  width: 100%;
  display: inline-block;
  margin-top: 3px;
}

.stw-sider-notification-text {
  margin-top: 8px;
  font-weight: 300;
  line-height: 16px;
}

.stw-sider-notification-link {
  width: 20px;
  height: 20px;
  background-color: #4e4e4e;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 90%;
  background-image: url(../../icon/link.png);
  margin-top: 5px;
}

.stw-sider-user-box {
  cursor: pointer;
  margin: 0px 10px;
}

.stw-sider-user-box .ant-dropdown-trigger {
  border-radius: 8px;
  border: 1px solid #606060;
}

.ant-layout-sider-collapsed .stw-sider-user-box {
  border-color: #333;
}

.ant-layout-sider-collapsed .stw-sider-user-box .stw-sider-user-info {
  display: none;
}

.ant-layout-sider-collapsed .stw-sider-user-box .ant-dropdown-trigger {
  border: 0px !important;
}

.stw-sider-user {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  padding: 8px;
}

.stw-sider-user-tag {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 1px solid #fff;
  padding: 5px;
}

.stw-sider-user .ant-tag {
  margin-right: 0px;
  font-weight: 700 !important;
  font-size: 13px !important;
  font-family: 'Roboto', sans-serif !important;
  color: #333 !important;
  line-height: 13px !important;
  background-color: #ceff00;
  width: 38px;
  height: 38px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px !important;
}

.stw-sider-user-box .ant-dropdown-open,
.stw-sider-user-box:hover .ant-dropdown-trigger {
  background-color: #4e4e4e !important;
  border-color: #4e4e4e !important;
}

.stw-sider-user-box .ant-dropdown-open {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.stw-sider-user-box:hover .stw-sider-user-tag,
.stw-sider-user-box .ant-dropdown-open .stw-sider-user-tag {
  border-color: #ceff00 !important;
}

.stw-sider-user-box:hover .stw-sider-user-tag .ant-tag,
.stw-sider-user-box .ant-dropdown-open .stw-sider-user-tag .ant-tag {
  background-color: #333;
  color: #ceff00 !important;
}

.stw-sider-user .stw-sider-user-info {
  margin-left: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stw-sider-user .stw-sider-user-info .ant-typography {
  width: 100%;
  display: inline-block;
  color: #fff;
  line-height: 18px;
}

.stw-dropdown-user .ant-dropdown-menu {
  border-radius: 8px !important;
  background-color: #4e4e4e !important;
  box-shadow: unset !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  margin-bottom: -5px;
}

.ant-layout-sider-collapsed .stw-dropdown-user .ant-dropdown-menu {
  margin-bottom: 0px;
}

.user-menu-item {
  padding: 5px 8px;
  background-color: transparent !important;
}

.user-menu-item .ant-dropdown-menu-title-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user-menu-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  width: 34px;
  height: 34px;
  margin-right: 0px;
}

.user-menu-icon.station {
  background-image: url(../../icon/menu/icon-menu-devices.png);
}

.user-menu-icon.profile {
  background-image: url(../../icon/menu/icon-menu-profile.png);
}

.user-menu-icon.application {
  background-image: url(../../icon/menu/icon-application-info.png);
}

.user-menu-icon.language {
  background-image: url(../../icon/menu/icon-menu-configuration.png);
}

.user-menu-icon.logout {
  background-image: url(../../icon/menu/icon-menu-logout.png);
}

.user-menu-item:hover .user-menu-icon.station {
  background-image: url(../../icon/menu/icon-menu-devices-green.png);
}

.user-menu-item:hover .user-menu-icon.profile {
  background-image: url(../../icon/menu/icon-menu-profile-green.png);
}

.user-menu-item:hover .user-menu-icon.application {
  background-image: url(../../icon/menu/icon-application-info-green.png);
}

.user-menu-item:hover .user-menu-icon.language {
  background-image: url(../../icon/menu/icon-menu-configuration-green.png);
}

.user-menu-item:hover .user-menu-icon.logout {
  background-image: url(../../icon/menu/icon-menu-logout-green.png);
}

.user-menu-text {
  color: #fff;
  flex: 1;
  margin-left: 5px;
  font-size: 13px;
  padding: 5px 8px;
  border-radius: 5px;
}

.user-menu-item:hover .user-menu-text {
  background-color: #333;
}

.modal-user-row {
  margin-top: 15px;
}

.modal-user-row.application {
  margin-top: 20px;
}

.modal-user-row .logo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
}

.modal-user-row.with-button {
  justify-content: center;
  display: flex;
  margin-top: 30px;
}

.modal-user-row .ant-typography {
  width: 100%;
  display: inline-block;
}

.modal-user-row.application .ant-typography {
  text-align: center;
}
/* SIDER MENU */

/* PAGE HEADER */
.stylewhere-header-row {
  width: 100%;
  position: relative;
  margin-bottom: 15px;
}

.ant-page-header {
  border-radius: 10px;
  margin-bottom: 0px;
  padding: 17px 15px;
  background-color: #fff;
}

.stylewhere-header-row.boxed {
  margin-bottom: 0px;
}

.stylewhere-header-row.boxed .ant-page-header {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.stylewhere-header-row.boxed .stw-filters-selected.inheader {
  margin-top: -10px;
  border-radius: 0px;
  padding-bottom: 15px;
  padding-top: 0px;
}

.ant-page-header-back {
  margin-right: 10px !important;
}

.ant-page-header-back-button {
  border: 0px !important;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex !important;
  border-radius: 20px;
}

.ant-page-header-back-button div {
  height: 40px;
  width: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 40px;
}

.stw-pagination {
  position: absolute;
  bottom: 0;
  left: 0px;
  display: flex;
  height: 32px;
}

.stw-border .stw-pagination {
  left: 10px;
  bottom: 10px;
}

.stw-pagination .stw-pagination-numbering {
  gap: 4;
  margin-right: 10px;
}

.stw-reload {
  border-radius: 5px !important;
  border: 1px solid #d9d9d9 !important;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 0px;
  cursor: pointer;
  gap: 0px !important;
}

.stw-reload .icona {
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 20px;
  margin-right: 0px;
}

.stw-reload:hover .ant-typography {
  color: #2f80ed !important;
}

.ant-page-header-back-button .anticon-arrow-left {
  font-size: 18px;
}

.ant-page-header.filters {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.ant-page-header .ant-page-header-heading-title,
.ant-page-header-heading-title {
  color: black;
  font-size: 20px;
}

.ant-page-header-heading-extra {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.ant-page-header-heading-extra > button {
  border-radius: 10px;
}

.stylewhere-columns-manager-btn {
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  position: relative;
  z-index: 10;
  background-color: #fff;
  margin-left: 0px;
  height: 40px;
}

.stylewhere-columns-manager-btn .ant-space {
  padding: 0px;
  height: 38px;
  width: 38px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  box-shadow: rgb(0 0 0 / 2%) 0px 1px 4px, rgb(0 0 0 / 5%) 1px -1px 4px inset !important;
}

.stylewhere-columns-manager-btn .ant-space div {
  height: 38px;
  width: 38px;
  background-repeat: no-repeat;
  background-position: 8px 8px;
  background-size: auto 22px;
}

.stylewhere-columns-manager-btn.reload .ant-space div {
  justify-content: center;
  align-items: center;
  display: flex;
}

.stylewhere-columns-manager-btn .ant-space-item {
  height: 20px;
}

.ant-page-header.tabs {
  padding: 16px 15px;
}

.ant-page-header-heading {
  min-height: 40px;
}

.ant-page-header .ant-page-header-heading-left {
  padding: 0px;
  margin: 0px;
}

.stylewhere-tab-page {
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  padding: 4px 0px 4px 4px;
}

.ant-page-header.tabs .ant-page-header-heading-title {
  margin-right: 0px;
}

.stylewhere-tab-bar {
  margin-bottom: 20px;
}

.stylewhere-tab-page .ant-page-header-tab {
  border-radius: 8px;
  padding: 0px 15px;
  border: 0px !important;
  height: 32px;
  line-height: 32px;
  box-shadow: none !important;
  margin-right: 4px;
  font-size: 14px;
  color: #333;
}

.stylewhere-tab-page .ant-page-header-tab.selected,
.stylewhere-tab-page .ant-page-header-tab:hover {
  background-color: #333;
  color: #fff;
}

.stylewhere-tab-page.stylewhere-tab-bar-vertical {
  padding: 0px;
  border: 0px;
  margin-bottom: 0px;
}

.stylewhere-tab-page.stylewhere-tab-bar-vertical .ant-page-header-tab {
  margin-right: 0px;
  width: 100%;
  height: 40px;
  line-height: 40px;
}

.stylewhere-tab-page.stylewhere-tab-bar-vertical span {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dcdcdc;
  display: inline-block;
  width: 100%;
}

.stylewhere-tab-page.stylewhere-tab-bar-vertical span:last-child {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: 0px;
}

/* PAGE HEADER */

/* BREADCRUMBS */
.stw-breadcrumbs-row {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.stw-breadcrumbs-row .anticon {
  font-size: 20px;
  margin-right: 5px;
  color: #333333 !important;
}

.stw-breadcrumbs-row .icona {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  width: 22px;
  height: 22px;
  margin-right: 5px;
}

.ant-breadcrumb span {
  color: #333333 !important;
}

.ant-breadcrumb span.selectable {
  cursor: pointer;
  text-decoration: underline;
}

.ant-breadcrumb span.active {
  font-weight: 700 !important;
  text-decoration: unset;
}

/* FINE BREADCRUMBS */

/* TABLE */
.ant-table table {
  table-layout: unset !important;
}

.ant-table-thead > tr > th {
  color: #333 !important;
  font-weight: 700 !important;
}

.ant-table .ant-col {
  font-size: 13px;
}

.ant-table-ping-right .ant-table-cell-fix-right-first::after,
.ant-table-ping-right .ant-table-cell-fix-right-last::after {
  display: none;
}

.ant-table-column-title {
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 20px;
  white-space: nowrap;
}

.stylewhere-table-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.stylewhere-table-actions .ant-btn {
  padding: 0px !important;
  width: 30px !important;
  min-height: 30px !important;
  border-radius: 15px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stylewhere-table-actions .ant-btn .anticon-eye {
  height: 14px;
}

.stylewhere-table-actions .ant-btn .anticon-edit {
  height: 16px;
}

/* END TABLE */
.ant-btn {
  font-size: 15px;
}

.ant-btn-link {
  color: #2f80ed !important;
  text-decoration: underline;
}

.detail-cell {
  background-color: #fafafa;
  border: 0;
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;
  position: relative;
}

.detail-cell .ant-card-head-title {
  color: #000;
  font-weight: bold;
  font-size: 14px;
  text-transform: capitalize;
  padding: 0;
}

.detail-cell .ant-card-head {
  border: 0;
  padding: 0px;
  min-height: auto;
  text-align: left;
}

.detail-cell .ant-card-body {
  padding: 0px;
  text-align: left;
}

.detail-cell .ant-card-body p {
  margin-bottom: 0px;
  margin-top: 5px;
  font-size: 14px;
}

.detail-cell .copy {
  position: absolute;
  right: 10px;
  top: 50%;
  height: 40px;
  width: 40px;
  background-color: #ebebeb;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: -20px;
  border-radius: 20px;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.detail-cell:hover .copy {
  opacity: 1;
}

/* FORM */
.ant-form-item-label > label {
  color: #333 !important;
  font-weight: 700 !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: 'Roboto', sans-serif !important;
  line-height: 1;
  margin-left: 5px;
  content: '*';
}

/*.stylewhere-form-attributes .ant-space-item:nth-child(1),
.stylewhere-form-attributes .ant-space-item:nth-child(2) {
  flex: 1;
}*/

.stylewhere-form-attributes .stylewhere-form-item {
  margin-bottom: 15px;
}

.stylewhere-form-attributes .ant-form-item-label label {
  height: auto;
}

.stylewhere-form-options .ant-space-item:nth-child(1) {
  flex: 1;
}

.stylewhere-form-options .stylewhere-form-item {
  margin-bottom: 15px;
}

.stylewhere-form-item-autocomplete-add {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.stylewhere-form-item-autocomplete-add .ant-form-item {
  width: calc(100% - 100px) !important;
  margin-bottom: 0px !important;
  display: inline-block;
}

.stylewhere-form-item-autocomplete-add .stylewhere-button-secondary {
  margin-left: 10px;
  width: 90px;
  height: 40px !important;
  margin-top: 30px;
}

.stylewhere-select-with-autocompletion {
  padding-left: 10px;
  padding-right: 10px;
}

.stylewhere-select-with-autocompletion .stylewhere-select-with-autocompletion-select {
  padding-right: 3px !important;
}

.stylewhere-select-with-autocompletion .stylewhere-select-with-autocompletion-button {
  padding-left: 3px !important;
}

.stylewhere-select-with-autocompletion .stylewhere-select-with-autocompletion-button .stylewhere-button-secondary {
  height: 40px !important;
}

.stylewhere-form-item-slider-input {
  padding: 0px !important;
  background-color: #f2f2f2;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 32px;
  margin-top: 6px;
}

.stylewhere-form-item-slider-input .ant-input-number {
  width: 100% !important;
}

.stylewhere-form-attributes .anticon:not(.anticon-close-circle) {
  margin-top: 25px;
}

.stylewhere-form-attributes .ant-select-selection-item-remove .anticon.anticon-close,
.stylewhere-form-attributes .stylewhere-button-default .anticon.anticon.anticon-delete {
  margin-top: 0px;
}

.stylewhere-form-attributes .ant-select-arrow .anticon {
  margin-top: 0px;
}

.ant-switch {
  background-color: #333;
}

.ant-switch-checked {
  background-color: #75eba8;
}

.stylewhere-dropdown-select-tags {
  display: none !important;
}

/* END FORM */

.ant-image-img-placeholder.detail-image {
  border: 1px solid #fafafa;
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;
}

.ant-page-header-heading-extra {
  margin: 0px !important;
}

.stylewhere-button-default {
  background-color: #dcdcdc !important;
  color: #333 !important;
  font-weight: 700;
  border-radius: 8px !important;
  //border-color: #333333 !important;
  padding: 0px 15px !important;
  line-height: 40px !important;
  height: 40px !important;
  min-width: 120px;
  box-shadow: rgb(0 0 0 / 2%) 0px 1px 4px, rgb(0 0 0 / 5%) 1px -1px 4px inset !important;
}

.ant-page-header .stylewhere-button-default,
.ant-page-header .stylewhere-button-secondary {
  padding: 0px 15px !important;
  height: 40px !important;
  min-width: 120px;
  border-color: #e3e3e3 !important;
  margin-right: 0px !important;
}

.ant-select-dropdown .stylewhere-button-secondary {
  flex: 0;
  margin-left: 10px;
  height: 40px !important;
  min-width: auto;
  padding: 8px 12px;
  display: 'block';
  cursor: 'pointer';
}

.ant-select-dropdown .stylewhere-button-secondary:disabled {
  opacity: 0.5;
}

.ant-page-header .btn-filter,
.stylewhere-form-title .btn-filter {
  padding: 0px 0px !important;
  width: 40px !important;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #fff !important;
  min-width: auto !important;
  //box-shadow: none !important;
}

.ant-page-header .btn-filter:hover,
.stylewhere-form-title .btn-filter:hover {
  background-color: #fff !important;
  box-shadow: none;
}

.ant-page-header .btn-filter.stylewhere-button-secondary,
.stylewhere-form-title .btn-filter.stylewhere-button-secondary {
  border-color: #333 !important;
  background-color: #333 !important;
}

.ant-page-header .btn-filter div,
.stylewhere-form-title .btn-filter div {
  width: 38px;
  height: 38px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 24px;
}

.ant-page-header .stylewhere-button-secondary .ant-typography {
  color: #fff;
}

.stylewhere-button-primary {
  background-color: #ceff00 !important;
  color: #333 !important;
  font-weight: 700;
  border-radius: 8px !important;
  border-color: #ceff00 !important;
  padding: 0px 15px !important;
  line-height: 40px !important;
  height: 40px !important;
  min-width: 120px;
  box-shadow: rgb(0 0 0 / 2%) 0px 1px 4px, rgb(0 0 0 / 5%) 1px -1px 4px inset !important;
}

.stylewhere-button-primary:hover {
  background-color: #e6ff7d !important;
}

.stylewhere-button-default:hover {
  background-color: #ededed !important;
}

.stylewhere-button-secondary {
  background-color: #333 !important;
  color: #fff !important;
  font-weight: 700;
  border-radius: 8px !important;
  border-color: #333 !important;
  height: 30px !important;
  min-width: 120px;
}

.stylewhere-button-secondary.removeOpts {
  height: 40px !important;
  min-width: auto;
  width: 100%;
  padding: 0px;
}

.stylewhere-form-title {
  color: #333333 !important;
  margin-bottom: 0px !important;
}

.stylewhere-form-item {
  width: 100% !important;
}

.ant-select-selector,
.ant-input {
  border-color: #c8c8c8 !important;
  border-radius: 8px !important;
}

.ant-form-item-has-error .ant-select-selector {
  border-color: #ff4d4f !important;
}

.ant-form-item-has-error :not(.ant-input-disabled).ant-input,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover,
.ant-select-selector:focus,
.ant-select-selector:focus-within,
.ant-input:focus {
  border-color: #2f80ed;
  outline: 0 !important;
  -webkit-box-shadow: 0 0 0 2px rgba(47, 128, 237, 0.5) !important;
  box-shadow: 0 0 0 2px rgba(47, 128, 237, 0.5) !important;
  border-color: #2f80ed !important;
  outline: 0 !important;
}

.ant-picker-input > input:hover {
  border: 0 !important;
}

.ant-input-number {
  border-radius: 8px;
  border-color: #c8c8c8;
}

.ant-input-number-input {
  padding: 8px 11px;
  height: 40px;
}

.ant-form-item-control-input-content .ant-slider {
  width: calc(100% - 12px) !important;
}

.ant-form-item-has-error :not(.ant-input-disabled).ant-input,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
  border-color: #ff7875 !important;
}

.ant-notification .ant-notification-notice {
  border-radius: 10px;
  cursor: pointer;
}

.ant-notification .ant-notification-notice-info {
  border: 1px solid #333;
}

.ant-notification .ant-notification-notice-error {
  border: 1px solid #ff4d4f;
}

.ant-notification .ant-notification-notice-info * {
  color: #333;
}

.ant-notification .ant-notification-notice-error * {
  color: #ff4d4f;
}

.ant-notification .ant-notification-notice-info .ant-notification-close-icon *,
.ant-notification .ant-notification-notice-error .ant-notification-close-icon * {
  color: rgba(0, 0, 0, 0.45);
}

/* SPIN */
.ant-spin.ant-spin-show-text .ant-spin-text {
  font-size: 12px;
  margin-top: 5px;
}

.stylewhere-spin-primary {
  margin-top: 0px !important;
}

.stylewhere-spin-primary .ant-spin-lg .ant-spin-dot i {
  background-color: #ceff00;
}

.stylewhere-spin-primary .ant-spin.ant-spin-show-text .ant-spin-text {
  color: #ceff00;
}

/* END SPIN */

/* MODAL */
.ant-modal-title {
  font-weight: bold;
  font-size: 20px;
}

.ant-modal-content {
  border-radius: 5px;
}

.ant-modal-title {
  color: #333;
}

.ant-modal-footer {
  text-align: center;
  padding-bottom: 30px;
  border-top: 0px;
}

.ant-modal-footer .ant-btn {
  background-color: #fff !important;
  color: #333 !important;
  font-weight: 700;
  border-radius: 5px !important;
  border-color: #333333 !important;
  padding: 0px 30px !important;
  line-height: 40px !important;
  height: 40px !important;
  min-width: 140px;
}

.ant-modal-footer .ant-btn-primary {
  background-color: #ceff00 !important;
  border-color: #ceff00 !important;
}

.ant-modal-footer .ant-btn:nth-child(2) {
  margin-left: 15px !important;
}

.modal-list-element,
modal-list-element-bold {
  font-size: 15px;
}

.modal-list-element-bold {
  font-weight: bold;
}

/* END MODAL */

/* RANGE PICKER */
.ant-picker-header > button,
.ant-picker-header-view > button {
  color: #333;
}

/* END RANGE PICKER */

/* CARD */
.stylewhere-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: calc(100% + 15px);
  margin-top: 10px;
}

.stylewhere-card {
  min-width: calc(33.333333% - 15px);
  //max-width: calc(33.333333% - 15px);
  flex: 1;
  margin-right: 15px;
}

.stylewhere-card-content {
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  width: 100%;
}

.row-cards-inventory {
  margin-top: 15px;
}

.row-cards-inventory .stylewhere-cards {
  margin-top: 0px !important;
}

.row-cards-inventory .stylewhere-card-content {
  margin-bottom: 0px;
}

.stylewhere-card-head {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.stylewhere-card-head .ant-tag {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px !important;
}

.stylewhere-card-head h4 {
  margin-bottom: 0px;
  flex: 1;
}

.stylewhere-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  border-radius: 5px;
  width: 100%;
}

.stylewhere-card-body h1 {
  font-size: 50px;
  margin: 0px;
}

/* END CARD */

/* MANAGER COLUMNS TABLE */
.stylewhere-columns-manager-layer {
  position: absolute;
  right: 0px;
  top: 36px;
}

.stylewhere-columns-manager-layer.visible {
  width: 300px;
  border-radius: 8px;
  background-color: #fff;
  padding: 15px;
  box-shadow: 0px 0px 6px rgba(51, 51, 51, 0.4);
}

.stylewhere-columns-manager-layer-title {
  flex-direction: row;
  display: flex;
  width: '100%';
  margin-bottom: 5px;
}

.stylewhere-columns-manager-layer-title .ant-typography {
  margin-bottom: 0px;
  flex: 1;
  line-height: 20px;
}

.stylewhere-columns-manager-layer-title .ant-btn-link {
  padding: 0px;
  border: 0px;
  line-height: 20px;
  height: auto;
  font-size: 14px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.stylewhere-columns-manager-layer-title .ant-btn-link span {
  margin-left: 0px;
}

.stylewhere-columns-manager-layer-content {
  width: 100%;
}

.stylewhere-columns-manager-layer-content-overflow {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 0px;
  width: 100%;
}

.stylewhere-columns-manager-layer-content-overflow div {
  width: 270px;
}

.stylewhere-columns-manager-layer-content-overflow div > div {
  margin-top: 10px;
  position: static !important;
}

.stylewhere-columns-manager-layer-content-overflow span:not(.ant-checkbox) {
  flex: 1;
  display: flex;
  align-items: center;
}

.stylewhere-columns-manager-layer-content-overflow span:not(.ant-checkbox) span {
  flex: 0;
}

.stylewhere-columns-manager-layer-content-overflow span:not(.ant-checkbox) span.ant-typography {
  flex: 1;
  font-weight: 300 !important;
}

.stylewhere-columns-manager-layer-content-btn {
  margin-top: 20px;
  justify-content: flex-end;
  display: flex;
}

.stylewhere-columns-manager-layer-content-btn .stylewhere-button-default {
  line-height: 40px !important;
  min-width: 80px !important;
  margin-right: 10px;
  height: 40px !important;
}

.stylewhere-columns-manager-layer-content-btn .stylewhere-button-secondary {
  height: 38px !important;
  margin-left: 12px;
  border: 0px;
  min-width: 80px !important;
}

.stylewhere-columns-manager-layer-column {
  display: flex;
  flex-direction: row;
  margin-top: 0px;
}

.stylewhere-columns-manager-layer-column .drag-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 80%;
  width: 20px;
  height: 20px;
  margin-top: 6px;
  margin-right: 10px;
}

.stylewhere-columns-manager-layer-column .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.stylewhere-columns-manager-layer-column .ant-checkbox + span {
  padding-right: 0px;
  font-size: 12px;
  line-height: 20px;
  padding-top: 6px;
}

.stylewhere-columns-manager-layer-column .ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border-radius: 2px;
}

.stylewhere-columns-manager-layer-column .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}

.stylewhere-columns-manager-layer-column .ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: #fff;
  width: 6.71428571px;
  height: 10.14285714px;
  left: 24%;
}

/* END MANAGER COLUMNS TABLE */

/* FILTERS */
.stylewhere-filters {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stylewhere-filters-btn {
  flex: 1;
}

.stylewhere-filters-btn .stylewhere-button-secondary,
.stylewhere-filters-btn .stylewhere-button-default {
  height: 40px !important;
  line-height: 40px !important;
  padding: 0px 20px !important;
}

.stylewhere-filters-right {
  flex: 0;
}

.stylewhere-filters-form {
  margin-top: 0px;
  background-color: #fff;
  padding: 25px;
}

.stylewhere-filters-form.inheader {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.stylewhere-filters-form.absolute {
  position: absolute;
  top: 67px;
  left: 0px;
  width: 100%;
  z-index: 120;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 6px rgba(51, 51, 51, 0.4);
  -moz-box-shadow: 0px 0px 6px rgba(51, 51, 51, 0.4);
  -webkit-box-shadow: 0px 0px 6px rgba(51, 51, 51, 0.4);
  overflow-x: hidden;
  overflow-y: auto;
}

.stylewhere-filters-form .stylewhere-form-item {
  margin-bottom: 15px;
}

.stylewhere-filters-form .stylewhere-form-buttons {
  margin-bottom: 0px !important;
  margin-top: 10px !important;
}

.ant-btn.filter-tag * {
  font-size: 12px;
}

/* END FILTERS */

.stw-filters-selected {
  width: 100%;
}

.stw-filters-selected.inheader {
  margin-top: 15px;
  background-color: #fff;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-top: 5px;
}

.stw-filters-selected .filter-tag {
  margin-top: 10px !important;
  margin-left: 5px !important;
  border: 0px !important;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stw-filters-selected .filter-tag .ant-typography {
  color: #fff;
}

.stw-filters-selected .filter-tag .anticon {
  margin-left: 5px;
  height: 12px;
}

.stw-filters-selected-reset {
  flex: 0;
  border-left: 1px solid #ececec;
  margin-left: 15px;
  margin-top: 10px;
}

.stw-filters-selected-reset .ant-btn {
  margin-left: 10px;
  padding: 0px;
  display: flex;
  height: 24px;
  background-color: transparent !important;
}

.stw-filters-selected-reset .ant-btn span {
  margin-left: 5px;
  line-height: 24px;
}

.stw-filters-selected-reset .ant-btn span.anticon {
  margin-left: 0px;
}

.stw-card {
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  margin-bottom: 10px;
}

.stw-card.stw-card-small {
  display: flex;
}

.stw-card .stw-card-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  min-height: 40px;
}

.stw-card.stw-card-small .stw-card-head {
  width: 60%;
  height: auto;
}

.stw-card .stw-card-head .ant-tag {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0px !important;
}

.stw-card .stw-card-head span {
  font-size: 20px;
  font-weight: bold;
}

.stw-card .stw-card-body {
  font-size: 64px;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin: auto;
  padding: 20px 0;
}

.stw-card.stw-card-small .stw-card-body {
  width: 40%;
  font-size: 64px;
}

.stw-modal-page .ant-modal-body {
  padding: 0;
}

.stw-modal-page .ant-modal-footer {
  display: none;
}

.stw-border {
  border: 1px solid #ececec;
  border-radius: 10px;
}

.stw-inner-form {
  background-color: #ececec;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  margin: 0px;
}

.stw-inner-form.mt {
  margin-top: 25px;
}

.stw-inner-form .stw-inner-form-input {
  flex: 1;
  padding: 0px;
}

.stw-inner-form .stw-inner-form-input .stylewhere-form-item {
  margin-bottom: 0px;
}

.stw-inner-form .stw-inner-form-input > * {
  margin: 0;
}

.stw-inner-form .stw-inner-form-add {
  padding: 0px;
  margin-left: 20px;
}

.stw-inner-form .stw-inner-form-add .stylewhere-button-secondary {
  padding-left: 20px;
  padding-right: 20px;
}

.stw-nested-form {
  margin-top: 20px;
  background-color: #ececec;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
}

.stw-nested-form .stw-nested-form-input {
  display: inline-flex;
  flex-grow: 10;
  padding: 20px;
  padding-right: 0px;
}

.stw-nested-form .stw-nested-form-input > * {
  margin: 0;
}

.stw-nested-form .stw-inner-form-add {
  flex-grow: 1;
  padding: 0 20px;
  margin-top: 50px;
}

.stw-nested-form .stw-inner-form-add .stylewhere-button-secondary {
  height: 41px !important;
}

.stw-nested-form-input .stw-section-page {
  padding: 0 !important;
  background-color: #ececec !important;
}

/* ANTENNA CARD */
.swt-antenna-card,
.swt-placeholder-antenna-card {
  min-height: 500px;
  border-color: #9c9c9c;
  border-radius: 10px;
  margin-top: 15px;
}

.swt-antenna-card .stylewhere-form-item {
  margin-bottom: 15px;
}

.swt-placeholder-antenna-card {
  cursor: pointer;
  border-style: dashed;
}

.swt-placeholder-antenna-card .ant-card-body {
  text-align: center;
}

.stw-placeholder-antenna-card-content {
  margin-top: 223px;
}

/* END ANTENNA CARD */

.stw-grouping-card {
  border-radius: 10px;
  overflow: hidden;
}

.stw-workstation-rfid-antennas-collapse {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
}

.stw-workstation-rfid-antennas-collapse .ant-collapse-content-box {
  padding: 0px !important;
}

.stw-workstation-rfid-antennas-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
  padding-right: 0px;
  padding-left: 0px;
}

.stw-workstation-rfid-antennas-collapse .ant-collapse-item .ant-collapse-header {
  color: white;
}

.stw-grouping-card .ant-card-head {
  background-color: #333333;
  color: #ffffff;
}

.stw-grouping-card .ant-card-head-title .ant-typography {
  color: #fff;
  font-weight: bold;
}

.stw-grouping-card > .ant-card-body {
  padding-top: 0px;
}

.stw-grouping-card-content {
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 5px 1px rgba(110, 108, 110, 0.5);
  -moz-box-shadow: 0 0 5px 1px rgba(110, 108, 110, 0.5);
  box-shadow: 0 0 5px 1px rgba(110, 108, 110, 0.5);
  width: 100%;
}

.stw-grouping-card-content .ant-card-head {
  background-color: #fff;
}

.stw-grouping-card-content .ant-card-meta-title {
  color: #333333;
  font-weight: bold;
}

.stw-grouping-card-content .ant-card-body {
  padding: 20px 15px;
}

.stw-grouping-card-content-row {
  display: flex;
  flex-direction: row;
}

.stw-grouping-card-content-row-info {
  margin-right: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.stw-grouping-card-content-row-info .ant-typography {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  font-weight: 300;
}

.stw-grouping-card-content-row-tag {
  display: flex;
  flex-direction: row;
  margin-top: 7px;
  width: 100%;
}

.stw-grouping-card-content-row-tag .ant-tag {
  margin-right: 5px;
  border-radius: 5px;
}

.stw-grouping-card-content-row-tag .ant-tag:last-child {
  margin-right: 0px;
}

.stw-grouping-card-content-row-btn {
  min-width: 32px;
  max-width: 32px;
  flex: 0;
}

.stw-grouping-card-content-row-btn .ant-btn {
  margin: 0px !important;
}

.stw-grouping-card-placeholder {
  border-radius: 10px;
  overflow: hidden;
  border: 1px dashed #0d0c0c;
  height: 100%;
}

.stw-grouping-card-placeholder .ant-card-meta-title {
  color: #333333;
  font-weight: bold;
}

.stw-grouping-card-button {
  border-color: #333333;
  height: 70px;
  border-radius: 5px;
}

.ant-collapse-header {
  background-color: #333333;
}

.stw-workstation-rfid-antennas-card-remove-button,
.stw-workstation-balance-card-remove-button {
  background-color: black;
  border: 1px solid black;
}

.stw-workstation-rfid-antennas-modal {
  overflow: hidden;
  border-radius: 10px;
  top: 20px;
}

.stw-workstation-no-errors .stylewhere-editable-table-row .ant-form-item-explain-error {
  display: none !important;
}

.stw-workstation-rfid-antennas-modal .ant-modal-header {
  padding-bottom: 5px;
  padding-top: 10px;
}

.stw-workstation-rfid-antennas-modal .ant-modal-header h2 {
  margin-bottom: 0px;
}

.stw-workstation-rfid-antennas-modal.stw-modal-form .ant-modal-body {
  padding-bottom: 0px !important;
}

.stw-workstation-rfid-antennas-modal .ant-modal-content .ant-modal-body .ant-input-affix-wrapper {
  border: 0px !important;
  padding: 0px !important;
  box-shadow: none !important;
}

.stw-workstation-rfid-antennas-modal .ant-modal-content .ant-modal-body .ant-input-affix-wrapper input {
  border: 1px solid #c8c8c8 !important;
}

.stw-workstation-rfid-antennas-modal .ant-modal-content .ant-modal-body .ant-input-suffix {
  margin-left: 0px;
}

.stw-workstation-rfid-antennas-modal .ant-modal-content .ant-modal-body .ant-input-suffix .ant-btn {
  margin-left: 15px;
}

.stw-workstation-rfid-antennas-modal .ant-modal-content .ant-modal-footer {
  border-top: 0 none;
  text-align: right;
  padding-right: 24px;
}

/* FORM ELEMENT STYLE */
.ant-input {
  padding: 8px 11px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.stylewhere-form-attributes .stylewhere-button-secondary,
.stylewhere-form-options .stylewhere-button-secondary,
.stw-inner-form .stylewhere-button-secondary,
.stylewhere-form-title .stylewhere-button-secondary,
.stw-modal-button .stylewhere-button-secondary {
  height: 40px !important;
}

.ant-select-selection-overflow-item .ant-select-selection-item {
  background-color: #2f80ed !important;
  border-radius: 8px !important;
  padding: 0px 7px 0px 10px !important;
  border: 0px !important;
  margin: 0px 0px 0px 3px !important;
  display: flex !important;
  flex-direction: row;
  align-items: center;
}

.ant-select-selection-overflow-item .ant-select-selection-item-content,
.ant-select-selection-overflow-item .ant-select-selection-item-remove {
  color: #fff !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 38px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-selection-placeholder {
  line-height: 38px !important;
}

.ant-select-multiple .ant-select-selector {
  padding: 5px 4px !important;
}

.ant-picker {
  padding: 8px 11px 8px !important;
  border-color: #c8c8c8;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker {
  border-radius: 8px !important;
}

.ant-pagination-options .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 32px !important;
  border-radius: 5px !important;
  border: 1px solid #d9d9d9 !important;
}

.ant-pagination-options
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 32px !important;
}

/* END FORM ELEMENT STYLE */

.stw-not-found {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
}

.stw-not-found .icona {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  width: 100%;
  height: 120px;
  margin-right: 0px;
}

.stw-not-found .ant-typography {
  margin-top: 0px;
  font-size: 14px;
}

.stw-not-found .stw-not-found-title {
  margin-top: 15px;
  font-size: 18px;
}

/* TABLE */
.ant-table-cell { 
  background-color: #FFF !important;
  box-shadow: none !important;
}

.ant-table-cell.stw-extra-small {
  min-width: 80px !important;
  max-width: 80px !important;
  width: 80px !important;
}

.ant-table-cell.stw-small {
  min-width: 140px !important;
  max-width: 140px !important;
  width: 140px !important;
}

.ant-table-cell.stw-small-medium {
  min-width: 160px !important;
  max-width: 160px !important;
  width: 160px !important;
}

.ant-table-cell.stw-medium {
  min-width: 200px !important;
  max-width: 200px !important;
  width: 200px !important;
}

.ant-table-cell.stw-extra-medium {
  min-width: 260px !important;
  max-width: 260px !important;
  width: 260px !important;
}

.ant-table-cell.stw-large {
  min-width: 320px !important;
  max-width: 320px !important;
  width: 320px !important;
}

.ant-table-cell.stw-extra-large {
  min-width: 380px !important;
  max-width: 380px !important;
  width: 380px !important;
}

.ant-table-tbody td.ant-table-cell .ant-col {
  word-break: break-word;
}

.ant-table-tbody tr.ant-table-row:nth-child(even),
.ant-table-tbody tr.ant-table-row:nth-child(even) td {
  background: #f8f8f8 !important;
}
.ant-table-tbody tr.ant-table-row:nth-child(odd),
.ant-table-tbody tr.ant-table-row:nth-child(odd) td {
  background: #fff !important;
}

.ant-table-thead tr,
.ant-table-thead th {
  background: #fff !important;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: #dcdcdc !important;
}
/* END TABLE */

/* PAGINATION */
.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin-bottom: 0px;
  margin-top: 12px;
}

.stw-border .ant-table-pagination.ant-pagination {
  margin-bottom: 10px;
  padding: 0px 10px;
}

.ant-pagination-item,
.ant-pagination-item-link {
  border-radius: 5px !important;
  border: 1px solid #d9d9d9 !important;
}

.ant-pagination-jump-prev .ant-pagination-item-link,
.ant-pagination-jump-next .ant-pagination-item-link {
  border: 0px !important;
}
/* END PAGINATION */

.stw-table-opacity {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  z-index: 100;
}

.stw-multiple-title {
  display: flex;
  flex-direction: row;
}

.stw-multiple-title-element {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px dashed #ccc;
}

.stw-multiple-title-element:last-child {
  margin-right: 0px;
  padding-right: 0px;
  border-right: 0px;
}

.stw-multiple-title-element .ant-typography {
  width: 100%;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
}

.stw-multiple-title-element-label {
  color: #ccc;
  font-weight: 100 !important;
}

.stw-multiple-title-element-value {
  color: #333;
  font-weight: 700 !important;
}

.stylewhere-form-inline-options {
  width: calc(100% + 30px);
  padding: 15px 0px;
}

.stylewhere-form-inline-options:nth-child(even) {
  background: #fff !important;
}

.stylewhere-form-inline-options:nth-child(odd) {
  background: #f8f8f8;
}

.stylewhere-form-inline-options.header {
  padding-top: 0px !important;
  padding-bottom: 10px !important;
  background: #fff !important;
}

.stylewhere-form-inline-options .stylewhere-form-item {
  margin-bottom: 0px;
}

.stylewhere-form-inline-options .ant-space-vertical {
  gap: 0px !important;
}

/* EDITABLE TABLE */
.stylewhere-editable-table-row {
  display: flex;
  width: calc(100% + 30px);
  margin: 0px -15px;
  align-items: flex-start;
  padding: 15px;
}

.stylewhere-editable-table-row.header {
  padding: 0px 15px 10px;
}

.stylewhere-editable-table-row:nth-child(even) {
  background: #fff !important;
}

.stylewhere-editable-table-row:nth-child(odd) {
  background: #f8f8f8;
}

.stylewhere-editable-table-row-column {
  flex: 1;
  margin-right: 15px;
  max-width: calc(50% - 35px);
}

.stylewhere-editable-table-row-column.nomr {
  margin-right: 0px;
}

.stylewhere-form-attributes.modal-attribute .stylewhere-editable-table-row-column {
  margin: 0px;
  max-width: unset;
}

.stylewhere-editable-table-row-column .ant-space-vertical {
  gap: 0px !important;
  display: flex;
  justify-content: center;
  height: 40px;
}

.stylewhere-editable-table-row-column .ant-space-vertical .ant-space-item {
  display: flex;
  align-items: center;
}

/*.stylewhere-editable-table-row-column .ant-form-item-explain {
  display: none;
}*/

.stylewhere-form-attributes .ant-form-item-explain {
  margin-top: 3px;
}

.stylewhere-editable-table-row-column .stylewhere-form-item {
  margin-bottom: 0px !important;
}

.stylewhere-editable-table-row-action {
  width: 40px;
}

.stylewhere-editable-table-row-action .stylewhere-button-default {
  padding: 0px !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 20px !important;
  background-color: #fff !important;
  min-width: unset;
}
/* END EDITABLE TABLE */

/* SKELETON */
.stw-skeleton-full {
  width: 100%;
  border-radius: 8px !important;
}

.stw-skeleton-form-label:not(.mt) h3 {
  margin-top: 0px !important;
}

.stw-skeleton-form-label .ant-skeleton-paragraph {
  display: none !important;
}

.stw-skeleton-table {
  width: 100%;
}

.stw-skeleton-table-row {
  display: flex;
  flex-direction: row;
}

.stw-skeleton-table-row-column {
  padding: 16px;
  min-height: 20px;
  position: relative;
  flex: 1;
}

.stw-skeleton-table-row-column .actions {
  display: flex;
  justify-content: center;
  width: 100%;
}

.stw-skeleton-table-row-column .actions div:last-child {
  margin-left: 8px;
}

.stw-skeleton-table-row-column.small {
  min-width: 120px;
  max-width: 120px;
}

.stw-skeleton-table-row-column.large {
  min-width: 200px;
  max-width: 200px;
}

.stw-skeleton-table-row-column::before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  background-color: #dcdcdc;
  transform: translateY(-50%);
  transition: background-color 0.3s;
  content: '';
}

.stw-skeleton-table-list {
  overflow: hidden;
}

.stw-skeleton-table-list .stw-skeleton-table-row-column::before,
.stw-skeleton-table-row-column:last-child::before {
  display: none;
}

.stw-skeleton-table-list .stw-skeleton-table-row .stw-skeleton-table-row-column {
  align-items: center;
  display: flex;
}

.stw-skeleton-table-list .stw-skeleton-table-row:nth-child(even) {
  background: #fff !important;
}

.stw-skeleton-table-list .stw-skeleton-table-row:nth-child(odd) {
  background: #f8f8f8 !important;
}

.stw-skeleton-table-list .stw-skeleton-table-row-column {
  padding: 0px 16px;
}

.stw-skeleton-table-pagination {
  margin-top: 12px;
  height: 32px;
  display: flex;
  flex-direction: row;
}

.stw-skeleton-table-pagination-left {
  flex: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
}

.stw-skeleton-base {
  width: 100%;
}

.stw-skeleton-base ul li {
  border-radius: 8px !important;
}

.stw-skeleton-base.small {
  width: 30px;
  margin-right: 8px;
}

.ant-page-header-heading-title .stw-skeleton-base {
  margin-right: 0px;
  width: 200px;
}

.ant-page-header-heading-title .stw-skeleton-base .ant-skeleton-paragraph li {
  height: 20px;
  border-radius: 10px !important;
}

.stw-multiple-title .stw-skeleton-base.small {
  margin-right: 0px;
  width: 100px;
}

.stw-multiple-title .stw-skeleton-base .ant-skeleton-paragraph li {
  height: 16px;
}

.stw-multiple-title .stw-skeleton-base:last-child .ant-skeleton-paragraph li {
  margin-top: 8px;
}

.ant-card .stw-skeleton-base {
  margin-right: 0px;
  width: 100%;
}

.ant-card .stw-skeleton-base .ant-skeleton-paragraph li {
  height: 21px;
}

.ant-card .stw-skeleton-base:last-child .ant-skeleton-paragraph li {
  margin-top: 6px;
}

.ant-page-header-heading-title .stw-skeleton-base.tabs {
  width: 250px;
}

.ant-page-header-heading-title .stw-skeleton-base.tabs .ant-skeleton-paragraph li {
  height: 42px;
}

.stw-skeleton-table-pagination-left .stw-skeleton-base {
  width: 115px;
  margin-right: 10px;
}

.stw-skeleton-base .ant-skeleton-paragraph {
  margin-bottom: 0px;
}

.stw-skeleton-button .ant-skeleton-button-round {
  width: 32px;
  border-radius: 5px !important;
  margin-right: 8px;
}

.stw-skeleton-button .ant-skeleton-button.medium {
  width: 44px;
}

.ant-page-header-back-button .stw-skeleton-button .ant-skeleton-button {
  width: 40px;
  height: 40px;
}

.ant-page-header-heading-extra .stw-skeleton-button .ant-skeleton-button-round {
  width: 40px;
  height: 40px;
  border-radius: 8px !important;
  margin-right: 0px;
}

.ant-page-header-heading-extra .stw-skeleton-button.create .ant-skeleton-button-round {
  width: 140px;
}

.ant-page-header-heading-extra .stw-skeleton-button {
  margin-left: 10px;
}

.ant-page-header-heading-extra .stw-skeleton-button:first-child {
  margin-left: 0px;
}

.stw-skeleton-button .ant-skeleton-button.large {
  margin-right: 0px;
  margin-left: 8px;
  width: 100px;
}

.stw-skeleton-table-pagination-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}
/* END SKELETON */

/* NOTIFICATIONS */
.stw-sider-notification-list {
  padding: 15px;
  background-color: #fff;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 15px;
  box-shadow: 0px 0px 5px 0px #00000026;
  cursor: pointer;
}

.stw-sider-notification-list:last-child {
  margin-bottom: 0px;
}

.stw-sider-notification-list .stw-sider-notification-title .status {
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 10px;
}

.stw-sider-notification-list .stw-sider-notification-title .status.delete {
  background-image: unset;
  background-color: #fff;
  line-height: 28px;
  text-align: center;
  margin-right: 0px;
  margin-left: 15px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  border: 1px solid #d9d9d9;
}

.stw-sider-notification-list .stw-sider-notification-title span {
  line-height: 18px;
}

.stw-sider-notification-list .stw-sider-notification-title span.label {
  font-size: 14px;
}

.stw-sider-notification-list.new .stw-sider-notification-title span.label {
  font-weight: 700;
}

.stw-sider-notification-list .ant-skeleton-element {
  width: 100%;
}

.stw-notification-alert {
  border-left: 9px solid #ceff00 !important;
  border-radius: 5px;
  padding: 16px;
}

.stw-notification-alert .ant-notification-notice-with-icon .ant-notification-notice-icon {
  width: 22px;
  height: 22px;
  border-radius: 5px;
  background-color: #333;
  margin-left: 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-image: url(../../icon/menu/icon-menu-notification-green.png);
}

.stw-notification-alert .ant-notification-notice-with-icon .ant-notification-notice-icon svg {
  display: none;
}

.stw-notification-alert .ant-notification-notice-message {
  color: #828282;
  font-size: 12px;
  line-height: 22px;
  margin-left: 30px;
}

.stw-notification-alert .ant-notification-notice-description {
  margin-left: 0px;
  margin-top: 10px;
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
}

.stw-notification-alert .ant-notification-notice-close {
  background-color: #333;
  width: 18px;
  height: 18px;
  border-radius: 10px;
  text-align: center;
  line-height: 17px;
  top: 17px;
  right: 17px;
}

.stw-notification-alert .ant-notification-notice-close .ant-notification-close-icon {
  font-size: 10px;
  color: #fff;
}
/* END NOTIFICATIONS */

.ant-image-img-placeholder {
  min-height: 250px;
}

.ant-table-cell .ant-image-img-placeholder {
  max-height: 100px;
  min-height: 70px;
}

.ant-table-cell .ant-image-img-placeholder .stw-search-result-content {
  white-space: unset;
  text-overflow: unset;
}

/* MODAL SEARCH */
.stw-omni-search-layer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

.stw-omni-search-input {
  width: 36%;
  border-radius: 8px;
  height: auto;
  position: absolute;
  top: 50px;
  left: 32%;
  z-index: 110;
}

.stw-omni-search-input-box {
  background-color: #000;
  border-radius: 8px;
  overflow: hidden;
  padding: 0px;
}

.stw-omni-search-input-box.results {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.stw-omni-search-input .stw-omni-search-input-box .ant-input {
  padding: 0px 20px !important;
  height: 60px !important;
  background-color: #000 !important;
  border: 0px !important;
  color: #fff !important;
  font-size: 18px !important;
  box-shadow: none !important;
}

.stw-omni-search-results {
  background-color: #000;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  padding: 10px;
  padding-top: 0px;
  overflow-y: auto;
}

.stw-omni-search-results .stw-omni-search-result {
  border-radius: 8px;
  padding: 10px;
  background-color: #333 !important;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.stw-omni-search-results .stw-omni-search-result:last-child {
  margin-bottom: 0px;
}

.stw-omni-search-result-content {
  flex: 1;
}

.stw-omni-search-result-content .ant-typography {
  color: #fff;
  display: inline-block;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
}

.stw-omni-search-result-content .ant-typography.path {
  font-weight: 300;
  font-style: italic;
  font-size: 12px;
}

.stw-omni-search-result .anticon-arrow-right {
  font-size: 18px;
  color: #fff;
  height: 20;
}

.stw-omni-search-results .stw-omni-search-result.active {
  background-color: #ceff00 !important;
}

.stw-omni-search-results .stw-omni-search-result.active .ant-typography,
.stw-omni-search-results .stw-omni-search-result.active .anticon-arrow-right {
  color: #333 !important;
}
/* END MODAL SEARCH */

/* TABLE */
.column-filter.stw-extra-small,
.column-filter.stw-small,
.column-filter.stw-medium {
  min-width: 220px !important;
  max-width: 220px !important;
  width: 220px !important;
}

.column-filter.stw-extra-medium {
  min-width: 240px !important;
  max-width: 240px !important;
  width: 240px !important;
}

.column-filter.stw-large {
  min-width: 300px !important;
  max-width: 300px !important;
  width: 300px !important;
}

.column-filter.stw-extra-large {
  min-width: 340px !important;
  max-width: 340px !important;
  width: 340px !important;
}

.column-filter .stylewhere-form-item {
  margin-bottom: 8px !important;
}

.column-filter .stylewhere-button-primary,
.column-filter .stylewhere-button-secondary {
  padding: 0px 10px !important;
  height: 28px !important;
  line-height: 26px !important;
  min-width: auto !important;
  font-size: 14px !important;
}

.column-filter .ant-select-selection-overflow-item {
  max-width: 70%;
}
/* FILTER COLUMN */

.ant-select-item.hasmessage .ant-select-item-option-content {
  white-space: unset;
}

@media (max-width: 870px) {
  .stylewhere-form-attributes {
    display: inline-block !important;
    gap: 0px !important;
    margin-bottom: 0px !important;
  }

  .stylewhere-form-attributes .ant-space-item {
    display: inline-block !important;
    width: 100% !important;
    margin-bottom: 15px !important;
  }

  .stylewhere-form-attributes .ant-space-item:last-child {
    margin-bottom: 0px !important;
  }

  .stylewhere-form-attributes .ant-space-item:last-child p {
    display: none !important;
  }

  .ant-page-header-heading-extra .ant-btn.stylewhere-button-default,
  .ant-page-header-heading-extra .ant-btn.stylewhere-button-primary {
    padding: 0px 15px !important;
    min-width: auto !important;
  }

  .ant-page-header-heading-extra .stylewhere-button-default.btn-filter {
    padding: 0px !important;
  }

  .stylewhere-editable-table-row.header {
    display: none;
  }

  .stylewhere-editable-table-row {
    display: inline-block;
    position: relative;
  }

  .stylewhere-editable-table-row .stylewhere-editable-table-row-column {
    margin-bottom: 15px;
    margin-right: 0px !important;
  }

  .stylewhere-editable-table-row .stylewhere-editable-table-row-column:first-child {
    width: calc(100% - 50px);
  }

  .stylewhere-editable-table-row-action {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .stylewhere-editable-table-row-action .stylewhere-button-default {
    height: 30px !important;
    line-height: 30px !important;
    border-radius: 8px !important;
  }
}
